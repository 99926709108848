@use "sass:math";

@mixin selectFontColor($color) {
  h1,
  h2,
  h3,
  h4,
  p,
  li,
  div {
    color: $color;
  }
}

@mixin fluidFontSize($base-font: 16px, $multiplier: 1vw, $line-height: 1.2rem) {
  font-size: $base-font;
}

@mixin fluidMargin($base-font: 16px, $multiplier: 1.5vw, $line-height: 1.4) {
  $rem: calc(#{$base-font} + #{$multiplier});
  margin-bottom: calc((#{$rem} + (#{$rem} * #{$multiplier})));
}

@mixin lineHeight($font-size, $multiplier: 1.4, $step: 2, $modifier: 1vw) {
  $line-height: calc((#{$font-size} * #{$multiplier}));
  line-height: $line-height;
}

@mixin marginBottom($font-size, $multiplier: 1) {
  margin-bottom: calc((#{$font-size} * #{$multiplier}));
}

@mixin marginHeading() {
  margin-top: calc(1.5 * (1em + 0.5vw));
  margin-bottom: 0;
}

@mixin marginBody() {
  margin: calc(1em + 0.5vw) 0;
}

@mixin modifiers($map, $attribute, $prefix: '-', $separator: '-', $base: 'base') {
  @each $key, $value in $map {
    &#{if($key != $base, #{$prefix}#{$key}, '')} {
      @if type-of($value) == 'map' {
        @include modifiers($value, $attribute, $separator);
      } @else {
        #{$attribute}: $value;
      }
    }
  }
}

@mixin columns($attribute, $cols: 12) {
  @for $i from 1 through $cols {
    &#{-$i} {
      #{$attribute}: math.div(100, math.div($cols, $i)) * 1%;
    }
  }
}

@mixin makeResponsive {
  @content;

  @each $key, $value in $breakpoints {
    @media screen and (max-width: $value) {
      @content;
    }
  }
}

$base-font-size: 1em;
$base-line-height: 1.8em;
$cap-height: 0.66;

@mixin baseline($font-size, $scale: 2) {
  // rhythm unit
  $rhythm: $base-line-height * $font-size / $scale;
  // number of rhythm units that can fit the font-size
  $lines: ceil(($font-size + 0.001px) / $rhythm);
  // calculate the new line-height
  $line-height: ($rhythm * $lines) / $font-size;
  // use the results
  font-size: #{$font-size};
  line-height: #{$line-height + em};
  $baseline-distance: (($line-height - $cap-height) / 2) + em;
  padding-top: calc(#{$baseline-distance});
  margin-bottom: calc((#{$base-line-height} - #{$baseline-distance}));
}

@mixin lhCrop($font-size, $multiplier: 1.4, $capital-letter: 1) {
  text-edge: cap alphabetic;
  leading-trim: both;
}

@mixin fillContainer() {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}

@mixin etchedBackgroundFull(
  $background-color: transparent,
  $opacity: 0.42,
  $background-size: cover
) {
  &::before {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: $background-color;
    background-image: url(https://media.firstbusiness.bank/image/upload/q_auto,f_auto/v1621975675/new-site/etching-background_2x_nvxopt.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-size: $background-size;
    opacity: $opacity;
    content: '';
    left: 0;
    top: 0;
    z-index: 0;
  }
}

@mixin cardShadow {
  &::before {
    content: '';
    z-index: -1;
    box-shadow: var(--ds-shadow);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: absolute;
  }
}

@mixin cardEtching {
  background-image: url(https://media.firstbusiness.bank/image/upload/f_auto,q_auto/v1621977944/new-site/normal-card-back_qtfst6.jpg);
  background-position: -1px bottom;
  background-repeat: no-repeat;
  background-size: calc(100% + 2px);
  background-color: #fefefe;
}

@mixin hasHover {
  @media not all and (hover: none) {
    @content;
  }
}

@mixin isIE {
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    @content;
  }
}

@mixin footerLink() {
  color: var(--text-color-light);
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.75;
  padding: 0;
  margin: 0 0 var(--spacer-1);
  a {
    font-weight: 400;
    font-size: 0.875rem;
    &.small {
      font-size: 0.875rem;
    }
  }
}

@mixin chevronIcon() {
  &::after {
    content: url('https://media.firstbusiness.bank/image/upload/v1621977976/new-site/chevron_hmypjo.svg');
    font-size: 0.75em;
    line-height: 1;
    position: absolute;
    right: -1em;
    font-weight: 900;
    color: var(--primary-color-light);
    width: 1em;
    height: auto;
    bottom: 0.05em;
  }
  &.reverse {
    &::after {
      content: url('https://media.firstbusiness.bank/image/upload/v1621977976/new-site/chevron_reverse_n2cztk.svg');
    }
  }
  &.reverse-hover {
    &::after {
      content: url('https://media.firstbusiness.bank/image/upload/v1621977976/new-site/chevron_reverse_n2cztk.svg');
    }
    &:hover{
      &::after {
        content: url('https://media.firstbusiness.bank/image/upload/v1621977976/new-site/chevron_hmypjo.svg');
      }
    }
  }
}

@mixin formStyles {
  label,
  label b,
  label strong {
    font-weight: 600;
  }

  input[type='checkbox'] {
    display: inline-block;
  }

  input[type='text'],
  input[type='email'],
  input[type='number'],
  input[type='password'],
  input[type='tel'],
  select,
  textarea {
    display: flex;
    align-items: center;
    padding: 0 var(--spacer-2);
    max-width: 600px;
    max-width: 100%;
    height: var(--button-height, 45px);
    font-size: 1rem;
    transition: all var(--animation-speed-fast, 0.25s) var(--animation-easing-linear);
    width: 100%;
    &:focus {
      outline: none;
    }
  }

  select[multiple='multiple'] {
    height: auto;
  }

  button {
    border: solid 1px transparent;
    box-shadow: var(--shadow);
    transition: all 0.25s linear;
    position: relative;
    font-weight: 500;
    border-radius: var(--ds-button-border-radius, 0);
    max-width: max-content;
    line-height: 0.5;
    width: 100%;
    box-sizing: border-box;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 0 var(--ds-button-padding-regular-horizontal);
    height: 58px;
    margin-top: 24px;
    background-color: var(--ds-color-primary);
    color: var(--ds-button-color-primary-foreground, #fff);
    &:hover,
    &:focus {
      background-color: var(--ds-color-primary-light);
    }
  }

  textarea {
    width: 100%;
    height: 400px;
  }
}
