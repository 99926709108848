$width-xs: 0;
$width-sm: 544px;
$width-md: 810px;
$width-lg: 1008px;
$width-xl: 1280px;
$breakpoints: (
  sm: $width-sm,
  md: $width-md,
  lg: $width-lg,
  xl: $width-xl
) !default;

@mixin breakpoint($size) {
  @media screen and (max-width: map-get($map: $breakpoints, $key: $size)) {
    @content;
  }
}

@mixin min-breakpoint($size) {
  @media screen and (min-width: map-get($map: $breakpoints, $key: $size)) {
    @content;
  }
}

// @include breakpoint(md) {
//     //Example medium
// }
