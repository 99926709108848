@import '../design-system/src/scss/_breakpoints.scss';
@import '../design-system/src/scss/base.scss';

.no-fouc {
  //visibility: hidden;
  opacity: 0;
  transition: all 0.25s ease;
}

.fouc {
  //visibility: visible;
  opacity: 1;
}

.article-body {
  img {
    float: right;
    max-width: 100%;
    margin-left: 2rem;
    margin-bottom: 2rem;
  }

  iframe {
    display: block;
    width: 100%;
    margin: 0 auto 32px;
    object-fit: contain;
    aspect-ratio: 16 / 9;
  }

  .alignright {
    float: right;
    max-width: 100%;
    margin-right: 0;
    margin-left: 2rem;
  }

  .alignleft {
    float: left;
    max-width: 100%;
    margin-left: 0;
    margin-right: 2rem;
  }

  .aligncenter {
    display: block;
    float: none;
    max-width: 100%;
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    object-fit: contain;
    object-position: center;
  }

  .margin-top {
    margin-top: 2rem;
  }

  @include breakpoint(sm) {
    img {
      display: block;
    }

    .alignleft,
    .alignright {
      float: none;
      max-width: 100%;
      margin-left: 0;
      margin-bottom: 2rem;
      margin-top: 2rem;
      width: 100%;
      object-fit: contain;
    }
  }

  ol,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: var(--spacer-4);
  }

  ul,
  ol {
    list-style: disc;
    padding-left: 20px;
    li {
      margin-bottom: 8px;
    }
  }

  ol {
    list-style: decimal;
  }

  h3 {
    margin-top: var(--spacer-8);
  }
}

.article-snippet {
  display: -webkit-box;
  -webkit-line-clamp: 10;
  -webkit-box-orient: horizontal;
  overflow: hidden;
  text-overflow: ellipsis;
}

.rotate {
  transition: all var(--animation-speed-fast) ease;
  transform: rotate(0);
}

.no-scroll {
  overflow: hidden;
}

.no-flex-gap {
  // .flex-container {
  //   margin: 0 -16px;
  // }
  .flex-container > * {
    margin: 0 calc(((var(--flex-gap)) / 2)) var(--flex-gap);
  }
}

.full-width {
  width: 100% !important;
  max-width: 100% !important;
}

table {
  border-collapse: collapse;

  strong {
    font-size: inherit;
  }
}

.mktoFormRow {
  margin-bottom: 24px;
  color: var(--text-color-default);
}

.mktoAsterix {
  display: inline-block;
  margin-right: 4px;
}

#marketo-form-content {
  .mktoCheckboxList {
    display: flex;
    position:relative;
    input {
      margin-top: 3px;
    }
    input[type=checkbox] {
      position: absolute;
      top: 0;
    }
    label {
      margin-left: 24px;
    }
  }
  .mktoFieldWrap {
    select {
      background-color: #fff;
      border: 1px solid rgb(133,133,133);
      color: #000;
      min-height: 45px;
    }
  }
}
select.mktoField {
  background-color: #fff;
  border: 1px solid rgb(133,133,133);
  color: #000;
  min-height: 45px;
}

.mktoFormCol {
  color: var(--text-color-default);
}

.mktoFieldDescriptor {
  margin-bottom: 24px;
}

.mktoButton {
  text-transform: capitalize;
}

.mktoAsterix {
  display: none;
}

.line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.no-flex-gap {
  .flex-container {
    margin-left: 0 !important;
    margin-right: var(--flex-gap) !important;
    &:nth-of-type(2n + 2) {
      margin-right: 0 !important;
    }

    @include breakpoint(sm) {
      margin-left: 0 !important;
      margin-right: 0 !important;
    }
  }
}

.text-color {
  color: var(--text-color-dark);
}
#marketoForm {
  position:relative;
}
.cta-widgets {
  position: relative;
  z-index: 10;
  &:focus {
    z-index: 15;
  }
}

#cards {
  position: relative;
  z-index:5;
}

@media screen and (max-width: 767px) {
  iframe.podcast-iframe {
    height:176px;
  }
}

.mobile-only {
  display: none;

  @include breakpoint(md) {
    display: block;
  }
}

.desktop-only {
  display: block;

  @include breakpoint(md) {
    display: none;
  }
}
