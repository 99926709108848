@import 'fallback-tokens';

@supports (--color: red) {
  // :root {
  //     --color: red;
  // }
  // body {
  //     color: var(--color)};
  // }
}

// @function hsb($h-hsb, $s-hsb, $b-hsb, $a: 1) {
//   @if $b-hsb == 0 {
//     @return hsla(0%, 0%, 0%, $a);
//   } @else {
//     $l-hsl: ($b-hsb/2) * (2 - ($s-hsb/100)) * 1%;
//     $s-hsl: ($b-hsb * $s-hsb) / if($l-hsl < 50, $l-hsl * 2, 200 - $l-hsl * 2);
//     @return hsla($h-hsb * 1%, $s-hsl * 1%, ($l-hsl * 1%) / $a);
//   }
// }

$primary: (
  h: 218,
  s: 20,
  b: 16
);

$secondary: (
  h: 131,
  s: 38,
  b: 100
);

$gray: (
  h: 131,
  s: 4,
  b: 50
);

:root {
  --body-background-color: #ffffff;

  --primary-color: #{$ds-color-primary};
  --primary-color-light: var(--ds-color-primary-light);
  --primary-color-lighter: #{lighten($ds-color-primary, 15%)};
  --primary-color-lightest: #{lighten($ds-color-primary, 20%)};
  --primary-color-dark: #{darken($ds-color-primary, 5%)};
  --primary-color-darker: #{darken($ds-color-primary, 25%)};
  --primary-color-darkest: #{darken($ds-color-primary, 38%)};

  --secondary-color: #{$ds-color-secondary};
  --secondary-color-light: #{lighten($ds-color-secondary, 5%)};
  --secondary-color-lighter: #{lighten($ds-color-secondary, 25%)};
  --secondary-color-lightest: #{lighten($ds-color-secondary, 38%)};
  --secondary-color-dark: #{darken($ds-color-secondary, 5%)};
  --secondary-color-darker: #{darken($ds-color-secondary, 25%)};
  --secondary-color-darkest: #3d4e6d;

  --accent-color: #{$ds-color-accent};
  --accent-color-light: #{lighten($ds-color-accent, 5%)};
  --accent-color-lighter: #{lighten($ds-color-accent, 25%)};
  --accent-color-lightest: #{lighten($ds-color-accent, 38%)};
  --accent-color-dark: #{darken($ds-color-accent, 5%)};
  --accent-color-darker: #{darken($ds-color-accent, 25%)};
  --accent-color-darkest: #{darken($ds-color-accent, 38%)};

  --gray-color: rgba(0, 0, 0, 0.5);
  --gray-color-light: rgba(0, 0, 0, 0.3);
  --gray-color-lighter: rgba(0, 0, 0, 0.1);
  --gray-color-dark: rgba(0, 0, 0, 0.7);
  --gray-color-darker: rgba(0, 0, 0, 0.9);
  --gray-color-10: rgba(0, 0, 0, 0.1);
  --gray-color-20: rgba(0, 0, 0, 0.2);
  --gray-color-30: rgba(0, 0, 0, 0.3);
  --gray-color-40: rgba(0, 0, 0, 0.4);
  --gray-color-50: rgba(0, 0, 0, 0.5);
  --gray-color-60: rgba(0, 0, 0, 0.6);
  --gray-color-70: rgba(0, 0, 0, 0.7);
  --gray-color-80: rgba(0, 0, 0, 0.8);
  --gray-color-90: rgba(0, 0, 0, 0.9);

  --text-color-default: #{$ds-color-text-base};
  --text-color-light: #4f5863; //#{$ds-color-text-light};
  --text-color-white: #fff;
  --text-color-dark: #{$ds-color-text-base};
  --text-color-medium: #{$ds-color-text-mid};

  --success-color: #{$ds-color-utility-success};
  --warning-color: #{$ds-color-utility-warning};
  --error-color: #{$ds-color-utility-error};
  --info-color: #{$ds-color-utility-info};

  --border-color: #{rgb(0, 0, 0)};
  --input-color: #{rgb(0, 0, 96)};
  --divider-color: rgba(151, 151, 151, 0.2);

  .light-text {
    color: var(--text-color-light);
  }

  .color-primary {
    color: var(--primary-color);
  }
}

//@media (prefers-color-scheme: dark) {
body.dark {
  --body-background-color: #000;
  --text-color-default: #fff;
}
//}
