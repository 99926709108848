@import 'fallback-tokens';
@import '_breakpoints';

:root {
  @for $i from 0 through $ds-spacing-steps {
    --spacer-#{$i}: #{$ds-spacing-base * $i}px;
  }

  // --spacer: 8px;
  // --spacer-0: 0;
  // --spacer-1: 8px;
  // --spacer-1-5: 12px;
  // --spacer-2: 16px;
  // --spacer-3: 24px;
  // --spacer-4: 32px;
  // --spacer-5: 40px;
  // --spacer-6: 48px;
  // --spacer-7: 56px;
  // --spacer-8: 64px;
  // --spacer-12: 96px;
  // --spacer-20: 160px;

  --container-width: $ds-spacing-container-max-width;
  --container-width-mobile: $ds-spacing-container-mobile-max-width;

  --grid-gap: $ds-spacing-grid-gap;
}

p,
ul {
  margin-bottom: var(--spacer-4);
}

.negate-padding-horz {
  margin-left: calc(-1 * var(--spacer-6))!important;
  margin-right: calc(-1 * var(--spacer-6))!important;
  @include breakpoint(md) {
    margin-left: calc(-1 * var(--spacer-2))!important;
    margin-right: calc(-1 * var(--spacer-2))!important;
  }
}
