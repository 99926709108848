@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes slideLeft {
}

@keyframes slideRight {
}

@keyframes slideUp {
}

@keyframes slideDown {
}

@keyframes grow {
}

@keyframes shrink {
}

@keyframes rotate180 {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(180deg);
  }
}

.fade-in {
  animation-name: fadeIn;
  animation-direction: forward;
  animation-play-state: running;
  //opacity: 0;
}

.fade-out {
  animation-name: fadeOut;
  animation-direction: normal;
  animation-play-state: running;
  opacity: 1;
}

.slideLeft {
  animation-name: slideLeft;
}

.slideRight {
  animation-name: slideLeft;
}

.slideUp {
  animation-name: slideLeft;
}

.slideDown {
  animation-name: slideLeft;
}

.grow {
  animation-name: grow;
}

.shrink {
  animation-name: shrink;
}
