@import 'fallback-tokens';
@import '_mixins';
@import '_breakpoints';
//@import url('#{$ds-typography-import}');

:root {
  --primary-font: var(--ds-typography-body), sans-serif;
  --header-font: var(--ds-typography-header), sans-serif;
}

body {
  background-color: white;
  font-family: var(--primary-font);
  font-weight: 200;
  font-size: 1.125rem;
  line-height: 1.75;
  color: var(--ds-color-text-base);
}

p,
li,
h1,
h2,
h3,
h4,
td,
button,
span,
strong {
  word-break: break-word;
  hyphens: none;
}
h2 a {
  word-break: normal;
}
strong,
b {
  font-weight: 500;
  color: var(--ds-color-heading);
}

.italic {
  font-style: italic;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  text-transform: capitalize;
  margin: 0;
  color: var(--ds-color-text-heading);
}

h1,
h1 a,
h1 strong,
.h1,
.h1 a {
  text-transform: capitalize;
  font-size: var(--ds-typography-sizes-h-1-size);
  line-height: var(--ds-typography-sizes-h-1-line-height);
  font-weight: var(--ds-typography-sizes-h-1-font-weight);

  @include breakpoint(sm) {
    font-size: var(--ds-typography-sizes-h-1-mobile-size);
    line-height: var(--ds-typography-sizes-h-1-mobile-line-height);
  }
}

h1.h2 {
  color:var(--text-color-dark);
}

h2,
h2 a,
h2 strong,
.h2,
.h2 a {
  text-transform: capitalize;
  font-size: var(--ds-typography-sizes-h-2-size);
  line-height: var(--ds-typography-sizes-h-2-line-height);
  font-weight: var(--ds-typography-sizes-h-2-font-weight);

  @include breakpoint(sm) {
    font-size: var(--ds-typography-sizes-h-2-mobile-size);
    line-height: var(--ds-typography-sizes-h-2-mobile-line-height);
  }
}

h3,
h3 a,
h3 strong,
.h3,
.h3 a {
  font-size: var(--ds-typography-sizes-h-3-size);
  line-height: var(--ds-typography-sizes-h-3-line-height);
  font-weight: var(--ds-typography-sizes-h-3-font-weight);

  @include breakpoint(sm) {
    font-size: var(--ds-typography-sizes-h-3-mobile-size);
    line-height: var(--ds-typography-sizes-h-3-mobile-line-height);
  }
}

h4,
h4 a,
h4 strong,
.h4,
.h4 a {
  font-size: var(--ds-typography-sizes-h-4-size);
  line-height: var(--ds-typography-sizes-h-4-line-height);
  font-weight: var(--ds-typography-sizes-h-4-font-weight);
}

h5,
h5 a,
h5 strong,
.h5,
.h5 a {
  font-size: var(--ds-typography-sizes-h-5-size);
  line-height: var(--ds-typography-sizes-h-5-line-height);
  font-weight: var(--ds-typography-sizes-h-5-font-weight);
}

h6,
h6 a,
h6 strong,
.h6,
.h6 a {
  font-size: var(--ds-typography-sizes-h-6-size);
  line-height: var(--ds-typography-sizes-h-6-line-height);
  font-weight: var(--ds-typography-sizes-h-6-font-weight);
}

.intro-paragraph {
  font-size: 1.5rem;
  line-height: 1.5;
  color: rgba(60, 62, 65, 0.6); //Make this a token
  font-weight: 300;
  &.is-resource {
    color: var(--ds-color-text-heading);
    margin:0 0 30px;
    font-size: 1.75rem;
  }

  @include breakpoint(sm) {
    font-size: 1.375rem;
    line-height: 1.625;
  }
  &.dark {
    .intro-paragraph {
      color:#000;
    }
  }
}

.font-large {
  font-size: var(--ds-typography-sizes-h-4-size);
}
.font-small {
  font-size: 1rem;
}

.font-x-small {
  font-size: var(--ds-typography-sizes-h-6-size);
}

.font-600 {
  font-weight: 600;
}

.font-large {
  font-size: 1.5rem;
}
.highlight {
  color: var(--primary-color-light);
}

p.h3,
p.h4,
p.h4 a {
  color: var(--ds-color-text-heading);
}

.text-left {
  text-align:left;
}
.text-center {
  text-align:center;
}
.text-right {
  text-align:right;
}
