/**
 * Do not edit directly
 * Generated on Mon, 24 May 2021 18:38:25 GMT
 */

:root {
  --animation-easing-opacity: linear;
  --animation-easing-color: linear;
  --animation-easing-exit-stage: cubic-bezier(.14,.72,.81,1);
  --animation-easing-enter-stage: cubic-bezier(.3,.01,1,.5);
  --animation-easing-on-stage: cubic-bezier(0,.5,1,.5);
  --animation-easing-default: linear;
  --animation-easing-linear-in: cubic-bezier(0,0,1,1);
  --animation-easing-linearout: cubic-bezier(0,0,1,1);
  --animation-easing-linear-in-out: cubic-bezier(0,0,1,1);
  --animation-easing-expo-in: cubic-bezier(0.71,0.01,0.83,0);
  --animation-easing-expo-out: cubic-bezier(0.14,1,0.32,0.99);
  --animation-easing-expo-in-out: cubic-bezier(0.85,0,0.15,1);
  --animation-easing-circ-in: cubic-bezier(0.34,0,0.96,0.23);
  --animation-easing-circ-out: cubic-bezier(0,0.5,0.37,0.98);
  --animation-easing-circ-in-out: cubic-bezier(0.88,0.1,0.12,0.9);
  --animation-easing-sine-in: cubic-bezier(0.22,0.04,0.36,0);
  --animation-easing-sine-out: cubic-bezier(0.04,0,0.5,1);
  --animation-easing-sine-in-out: cubic-bezier(0.37,0.01,0.63,1);
  --animation-easing-quad-in: cubic-bezier(0.14,0.01,0.49,0);
  --animation-easing-quad-out: cubic-bezier(0.01,0,0.43,1);
  --animation-easing-quad-in-out: cubic-bezier(0.47,0.04,0.53,0.96);
  --animation-easing-cubic-in: cubic-bezier(0.35,0,0.65,0);
  --animation-easing-cubic-out: cubic-bezier(0.09,0.25,0.24,1);
  --animation-easing-cubic-in-out: cubic-bezier(0.66,0,0.34,1);
  --animation-easing-quart-in: cubic-bezier(0.69,0,0.76,0.17);
  --animation-easing-quart-out: cubic-bezier(0.26,0.96,0.44,1);
  --animation-easing-quart-in-out: cubic-bezier(0.76,0,0.24,1);
  --animation-easing-quint-in: cubic-bezier(0.64,0,0.78,0);
  --animation-easing-quint-out: cubic-bezier(0.22,1,0.35,1);
  --animation-easing-quint-in-out: cubic-bezier(0.9,0,0.1,1);
  --animation-speed-fast: 0.25s;
  --animation-speed-normal: 0.35s;
  --animation-speed-slow: 0.45s;
  --ds-color-primary: #762a5b;
  --ds-color-primary-light: #A4347B;
  --ds-color-secondary: #6589b9;
  --ds-color-tertiary: #869837;
  --ds-color-accent: #c97131;
  --ds-color-text-base: rgba(60, 62, 65, .85);
  --ds-color-text-heading: #3C3E41;
  --ds-color-text-mid: #495057;
  --ds-color-text-light: #ACB5BD;
  --ds-color-text-white: #fff;
  --ds-color-text-primary: #fff;
  --ds-color-text-secondary: #fff;
  --ds-color-text-accent: rgba(60, 62, 65, .85);
  --ds-color-gray-dark: #3B3E41;
  --ds-color-gray-mid: rgba(60,62,65,.85);
  --ds-color-gray-light: rgba(60,62,65,.25);
  --ds-color-utility-error: #d0245d;
  --ds-color-utility-warning: #d08924;
  --ds-color-utility-success: #24d048;
  --ds-color-utility-info: #24aed0;
  --ds-color-gradients-plum: linear-gradient(180deg, rgba(143,73,123,1) 0%, rgba(88,37,71,1) 60%);
  --ds-color-gradients-blue: linear-gradient(180deg, rgba(101, 137, 185, 1) 0%, rgba(61, 78, 109, 1) 60%);
  --ds-color-gradients-green: linear-gradient(180deg, rgba(131,149,56,1) 0%, rgba(93,107,44,1) 60%);
  --ds-color-gradients-orange: linear-gradient(180deg, rgba(202,113,49,1) 0%, rgba(171,96,43,1) 60%);
  --ds-shadow: -20px 20px 40px rgba(0, 0, 0, 0.1);
  --ds-border-radius: 5px;
  --ds-min-card-height: 400px;
  --ds-spacing-steps: 20;
  --ds-spacing-base: 8;
  --ds-spacing-container-max-width: 1100px;
  --ds-spacing-container-mobile-max-width: 95vw;
  --ds-spacing-grid-gap: 28px;
  --ds-typography-header: 'canada-type-gibson';
  --ds-typography-body: 'canada-type-gibson';
  --ds-typography-sizes-h-1-size: 4.5rem;
  --ds-typography-sizes-h-1-line-height: 5rem;
  --ds-typography-sizes-h-1-font-weight: 600;
  --ds-typography-sizes-h-1-mobile-size: 2.5rem;
  --ds-typography-sizes-h-1-mobile-line-height: 3rem;
  --ds-typography-sizes-h-2-size: 3rem;
  --ds-typography-sizes-h-2-line-height: 3.375rem;
  --ds-typography-sizes-h-2-font-weight: 600;
  --ds-typography-sizes-h-2-mobile-size: 2.0625rem;
  --ds-typography-sizes-h-2-mobile-line-height: 2.5rem;
  --ds-typography-sizes-h-3-size: 2.25rem;
  --ds-typography-sizes-h-3-line-height: 2.5rem;
  --ds-typography-sizes-h-3-font-weight: 600;
  --ds-typography-sizes-h-3-mobile-size: 2.25rem;
  --ds-typography-sizes-h-3-mobile-line-height: 2.25rem;
  --ds-typography-sizes-h-4-size: 1.5rem;
  --ds-typography-sizes-h-4-line-height: 1.5;
  --ds-typography-sizes-h-4-font-weight: 600;
  --ds-typography-sizes-h-5-size: 1.125rem;
  --ds-typography-sizes-h-5-line-height: 1.5;
  --ds-typography-sizes-h-5-font-weight: 600;
  --ds-typography-sizes-h-6-size: 0.875rem;
  --ds-typography-sizes-h-6-line-height: 1.125em;
  --ds-typography-sizes-h-6-font-weight: 600;
  --ds-typography-scale: 1.414;
  --ds-typography-import: 'https://fonts.googleapis.com/css?family=Lato:wght@400,700&display=swap';
  --ds-button-padding-small-horizontal: 16px;
  --ds-button-padding-small-vertical: 16px;
  --ds-button-padding-regular-horizontal: 32px;
  --ds-button-padding-regular-vertical: 25px;
  --ds-button-padding-large-horizontal: 48px;
  --ds-button-padding-large-vertical: 25px;
  --ds-button-border-radius: 4px;
  --ds-button-color-primary-foreground: #fff;
  --ds-button-color-primary-background: #762a5b;
  --ds-button-color-secondary-foreground: #fff;
  --ds-button-color-secondary-background: #fff;
  --ds-button-color-tertiary-foreground: #fff;
  --ds-button-color-tertiary-background: #c97131;
  --ds-card-width: 320px;
}
