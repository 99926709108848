@import '_reset';
@import '_normalize';
@import '_design-tokens';
@import '_variables';
@import '_spacing';
@import '_colors';
@import '_typography';
@import '_utilities';
@import '_animation';
@import '_icons';

body {
  padding: 0 !important;
  background-color: #fff; //var(--body-background-color);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  min-height: 100vh;
  // line-height: 1.5!important;
  // background-image: url(https://media.firstbusiness.bank/image/upload/q_auto,f_auto/new-site/footer-bottom-left_mwknbr.jpg),
  //   url(https://media.firstbusiness.bank/image/upload/f_auto,q_auto/new-site/footer-bottom-right_vbdkwi.jpg);
  // background-position: bottom left, bottom right;
  // background-repeat: no-repeat;
  // background-size: auto, auto;
}

a, .pseudo-link {
  font-family: var(--ds-typography-body);
  color: var(--primary-color-light);
  font-weight: 500;
  position: relative;
  transition: all var(--animation-speed-fast) linear;
  font-size: inherit;
  &:hover {
    color: var(--primary-color-dark);
  }

  &.small-font {
    font-size: 1rem;
  }

  &.text-color {
    color: var(--text-color);
  }
  &.clickable-card-link {
    display: flex;
    flex-basis: 320px;
    max-width: 320px;
    font-weight: inherit;
    font-size: inherit;
    color: inherit;
    h2 {
      color: var(--text-color-default);
    }
    span.card-chevron {
      position:relative;
      @include chevronIcon();
    }
    &:hover {
      h2 {
        color: var(--primary-color-light);
      }
    }
  }
}
.pseudo-link {
  background-color: none;
  cursor: pointer;
  &.default {
    color:blue;
    font-weight: 300;
    display: inline-block;
  }
}
.chevron {
  &,
  h1,
  h2,
  h3,
  h4,
  h5 {
    display: inline;
    &:hover {
      color: var(--primary-color-light);
    }
  }

  @include chevronIcon();
}

p,
li,
div {
  color: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  a {
    color: var(--text-color-default);
  }
}

.dark {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  li,
  div {
    color: var(--text-color-light);
  }
}

.formatted-list {
  list-style-type: disc;
  li {
    margin-bottom: var(--spacer-2);
  }
}

select {
  height: 68px;
  border: solid 2px #eaeaea;
  appearance: none;
  border-radius: 5px;
  width: 100%;
  padding: 0 var(--spacer-4);
}

img,
iframe {
  max-width: 100%;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  border-color: var(--primary-color-light);
  background-color: #fff;
}

form {
  label {
    display: block;
    font-weight: 600;
    line-height: 1.5;
    font-size: 1rem;
    margin-bottom: var(--spacer-1);
    color: var(--text-color-default);
  }
  // React Select Element
  .ds-select {
    font-family: inherit;
    font-size: 1rem;
    border-radius: var(--border-radius);
    transition: border-color var(--animation-speed-fast);
    padding: 0;
    outline: none;
    &.full-width {
      width: 100%;
      max-width: none;
    }
    &:focus-within {
      outline: none;
      [class*='-control'],
      [class*='-control']:focus,
      [class*='-control']:hover {
        border: 2px solid var(--primary-color-light);
        box-shadow: none;
        outline: none;
        svg {
          display: none;
        }
      }
    }
    [class*='-control'],
    [class*='-control']:focus,
    [class*='-control']:hover {
      border-radius: var(--border-radius);
      border: 2px solid #eaeaea;
      box-shadow: none;
      font-weight: 400;
      color: var(--gray-color-50);
      min-height: 55px;
      svg {
        display: none;
      }
      [class*='IndicatorsContainer'] {
        [class*='indicatorSeparator'] {
          display: none;
        }
        [class*='indicatorContainer'] {
          &:after {
            content: '';
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 7px 7px 0 7px;
            border-color: var(--primary-color-light) transparent transparent transparent;
            position: relative;
            top: 1px;
            left: -17px;
          }
        }
      }
    }

    &.medium {
      width: 40ch;
    }

    &.small {
      width: 20ch;
    }

    &.tiny {
      width: 5em;
      padding: 0 var(--spacer-1);
      text-align: center;
    }

    .help {
      font-weight: normal;
      color: var(--gray-color-dark, #999);
    }

    .ds-select.disabled {
      border-color: var(--gray-color-lighter);
      background-color: #f2f2f2;
    }

    .error {
      color: var(--error-color, #ff0000);
    }
  }
}

.modal-overlay {
  position:fixed;
  top:0;
  bottom:0;
  left:0;
  right:0;
  background-color:#000;
  opacity:.5;
  z-index:1000000000;
}

.modal-content {
  position: absolute;
  top:10%;
  left:10%;
  right:10%;
  padding:2rem;
  z-index:1000000000;
  background-color: var(--body-background-color);
  p {
    margin-bottom:0;
  }
}
.article-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.content-with-gutter {
  max-width: var(--container-max-width);
  margin-left:auto;
  margin-right:auto;
  main {
    --background-width: auto;
    --background-negative: auto;
  }
}

.tab-list {
  border-top: var(--border-width) solid var(--divider-color);
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  margin-bottom: calc(-1 * var(--spacer-6));
  li {
    display: flex;
    flex-grow: 1;
    text-align: center;
    border-right: var(--border-width) solid var(--divider-color);
    &:last-child {
      border-right: none;
    }
    a,
    button {
      line-height: 1.5;
      padding: var(--spacer-3) var(--spacer-2);
      width: 100%;
    }

    button {
      font-family: var(--ds-typography-body);
      color: var(--primary-color-light);
      font-weight: 500;

      &:hover {
        color: var(--primary-color-dark);
      }
    }
  }
}
@include breakpoint(md) {
  .tab-list {
    margin-bottom: calc(-1 * var(--spacer-2));
  }
  .privacy-notice-table tr td,
  .privacy-notice-table tr td strong,
  .privacy-notice-table tr td strong span {
      font-size:14px!important;
  }
}
@include breakpoint(sm) {
  .header-container {
    z-index: 10;
  }
  .article-container {
    flex-direction: column;
    + div {
      width: 100%;
    }
  }
  .tab-list {
    border-top: var(--border-width) solid var(--divider-color);
    justify-content: flex-start;
    flex-direction: column;
    margin-bottom: calc(-1 * var(--spacer-2));
    li {
      border-right: none;
      border-bottom: var(--border-width) solid var(--divider-color);
      &:last-child {
        border-bottom: none;
      }
    }
  }
  .cta {
    display: block;
    margin-bottom:2rem;
  }
}

.grid-two-col-row {
  display:grid;
  grid-template-columns: 50% 1fr;
  .cta {
    line-height: 1.5;
    margin-bottom:2rem;
  }
}

.search-card {
  min-height: 100px !important;
  max-width: 100% !important;
  flex: 1 1 100%;
  background-image: url(https://media.firstbusiness.bank/image/upload/q_auto,f_auto/v1621978025/new-site/footer-bottom-left_mwknbr.jpg),
    url(https://media.firstbusiness.bank/image/upload/f_auto,q_auto/v1621978025/new-site/footer-bottom-right_vbdkwi.jpg) !important;
  background-position: bottom left, bottom right !important;
  background-repeat: no-repeat !important;
  background-size: contain, contain !important;
  text-align: left !important;
}

.tracking-code {
  display:none;
}

// screen reader
.sr-only {
  border: 0 !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important;
}
